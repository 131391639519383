import { mapState } from 'vuex'

export default {
	data() {
		return {
			loginType: 'phone',
			currentPage: 'login',
			forgetPasswordType: 'contact', // [phone, email, contact]
			form: {
				player_id: '',
				phone: '',
				password: '',
				reg_fingerprint: '',
			},
			valid: true,
			loading: false,
			showPassword: false,
			rememberMe: true,
			country: '',
			isRequireRule: true
		}
	},
	computed: {
		...mapState({
			isApp: (state) => state.app.isApp,
		}),
		formRule() {
			return {
				phone: [
					(v) => !!v || `${this.tl('phoneNumberIsRequired')}`,
					(v) =>
						!v ||
						/^[\d\s ()+\\-]+$/.test(v) ||
						this.tl('phoneNumberFormatInvalid'),
				],
			}
		},
	},
	methods: {
		inputPlayerId(e) {
			this.form.player_id = e.trim().toLowerCase()
		},
		async login() {
			// Set login option, for next Login
			this.$cookie.set('login_option', this.loginType)

			this.loading = true

			await this.$refs.form.validate()

			if (this.valid) {
				try {
					if (this.loginType === 'username') {
						delete this.form.phone
					} else if (this.loginType === 'phone') {
						delete this.form.player_id
					} else {
						return this.$toast.success('Wrong login option')
					}

					this.form.rememberMe = this.rememberMe
					this.form.recaptchaToken = await this.getRecaptchaToken()
					const response = await this.$store.dispatch(
						'auth/login',
						this.form
					)
					if (!response) {
						this.resetRecaptcha()
					}
				} catch (error) { }
			}
			this.loading = false
		},
		changeRequiredRule(rule) {
			if (this.loginType === "username") {
				this.isRequireRule = false
			} else {
				this.isRequireRule = rule
			}
		},
	},
}
